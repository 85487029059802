/**==== Variable Define =====**/
$primary-color: rgb(141, 67, 54);

/**====Custom CSS=====**/
.container-fluid{
  .contentContainer{
    min-height: 440px;
  }
}
ul,li{
  margin: 0;
  padding: 0; 
  list-style-type: none;
}
.App{
  h1{
    font-size: 22px;
    font-weight: 700;
  }
  h2{
    font-size: 22px;
    font-weight: 700;
    &.videoTitle{
      font-size: 17px;
      font-weight: 700;
    }
  }
  h3, .modal-title.h4{
    font-size: 17px;
    font-weight: 700;
  }
  .btn-base{
    background: $primary-color;
    border-color: $primary-color;
    padding: 0 35px;
  }
}
.container-content{
  font-weight: normal; 
  text-align: justify;
}

.headerWrapper{
  margin: 0 0 20px 0;
  .input-group{
    margin-top: 24px;
  }
}

.font-devanagari-medium {
  font-family: "Upgrade Devanagari Medium";
}

.font-devanagari-regular {
  font-family: "Upgrade Devanagari Regular";
}

.top-right-link-col {
  margin-top: 25px;
}

.custom-modal {

  padding: 0px !important;
  .modal-dialog{
    max-width: 100VW !important;
    max-height: 100VH !important;

    .modal-content {
      height: 100vh;
    }
}

} 
.top-link {
  font-family: "Source Sans Pro Regular";
  color: #000 !important;
  font-size: 16px;
}
.top-link.first-link {
  padding-left: 0 !important;
}

.dropdown-toggle.nav-link {
  color: #000 !important;
}

.navbar {
  padding-left: 0 !important;
}
.navbar .container {
  margin: 0;
  padding-left: 0;
}

.topTitle{
  font-size: 24px; 
  color: #000; 
  letter-spacing: 2px;
  padding: 2px 0 5px 0;
  margin-top: 24px;
  font-family: "Upgrade Devanagari Medium";
  strong{
    font-weight: 700;
  }
}
.site-logo {
  margin-top: 10px;
}

.content-title {
  color: #5e2121;
  margin-bottom: 15px;
}

.text-content {
  color: #5E5E5E;
}

.card-links {
  line-height: 24px;
}

.link-view-more {
  color: #5e2121;
}

.img-cover {
  width: 150px;
  height: 200px !important;
  object-fit: cover;
}

.footer-top-row {
  background-color: #f2ce9f;
}

.btn-contact {
  background-color: rgba(0,0,0,0.5) !important;
  border: 0 !important;
}

.social-media-icons svg {
  margin-right: .5rem;
}

.footer-top-row .social-media-icons svg {
  color: #000;
}

.footer-middle-row {
  color: #fff;
  background-color: #5f4444;
}

.text-black{
	color: #000000;
}
.text-white{
	color: #fff;
}
.text-yellow {
  color: #f2ce9f;
}

.btn-footer-middle-row {
  background-color: #9c5c5c !important;
  border: 0 !important;
}

.link-footer-middle-row:hover {
  color: #fff;  
}

.footer-bottom-row {
  background-color: #5e2121;
}

.footer-bottom-row .social-media-icons svg {
  color: #fff;
}

.btn-primary{
  &.btn-base{
    &:hover{
      background: $primary-color;
      border-color:$primary-color;
      padding: 0 35px;
    }
  }
}
.site-badge{
  img{
    width: 36%;
    margin-top: -20px;
  }
}

/**====Main Left Nav====**/
ul{
  &.leftNav{
    list-style-type: none;
    margin:0;
    padding: 0;
    li{
      list-style-type: none;
      margin:0;
      padding: 0;
      a{
        border: 1px solid gray;
        padding:20px;
        display: block;
        color:#000;
        font-weight: 700;
        font-size:15px;
        text-align: center;
        &:hover, &.active{
          text-decoration: none;
          background: $primary-color;
          border: 1px solid #fff;
          color:#fff;
        }
      }
    }
  }
}

/**====Top Main Nav====**/
ul{
  &.topNav{
    list-style-type: none;
    margin:0;
    padding: 0;
    border-bottom: 1px solid $primary-color;
    li{
      list-style-type: none;
      margin:0;
      padding: 0;
      display: inline-block;
      a{
        padding:12px 20px;
        display: block;
        color:#000;
        font-weight: 700;
        font-size:15px;
        text-align: center;
        &:hover, &.active{
          text-decoration: none;
          background: $primary-color;
          color:#fff;
        }
      }
    }
  }
}



ul{
  &.taxonomyMenu{
    border-top: 1px solid #fff;
    a{
      color:#000
    }
    li{
      padding:8px 0 8px 16px;
      position: relative;
      span{
        cursor: pointer;
      }
      &:last-child{
          border-bottom: none;
          padding-bottom:8px;
        }
      .showChildNav{
        padding: 0 8px 0 0; 
        cursor: pointer;
        color:#5fa2dd;
        position: absolute;
        left: 0;
        i{
          font-size: 20px;
        }
      }
      ul{
        li{
          padding-left:16px;
          &:last-child{
            padding-bottom:0;
          }
          ul{
            li{
              padding-left:14px;
            }
          }
        }
      }
      .menuLevelOne, .menuLevelTwo, .menuLevelThree{
          display: none;
          &.active{
          display: block;
        }
      }
      // For Arrow Close Icon
      .fa-caret-right{
        &.active{
          &::before{
            content: "\f0d7";
          }
        }
      }
      // For Arrow Open Icon
      .fa-caret-down{
        &.active{
          &::before{
            content: "\f0da";
          }
        }
      }
      
    }
    
    .menuLevelOne, .menuLevelTwo, .menuLevelThree{
      margin-top: 0px;
    }

   &.searchResult {
     li, .menuLevelOne, .menuLevelTwo, .menuLevelThree{
      border:none;
     }
     li{
       ul{
         li{
          padding-left: 35px;
         }
       }
     }
     input{
       &.checkbox{
        width: 16px;
        height: 16px;
        vertical-align: middle;
       }
     }
   } 

   &.scriptures{
     li{
       padding: 0;
       .leftNavSpan {
        display: block;
        padding: 8px 0 8px 25px;
       }
       .showChildNav{
        left:10px;
        top: 8px;
       }
       .menuLevelOne, .menuLevelTwo, .menuLevelThree{
         margin-left: 20px;
       }
     }
    .activeElement{
      background: #8d4336;
      
      color: #fff;
    }
   }
  }
}

.fix-height{
  max-height: 450px; 
  overflow-y: auto;
}
.maxHeight{
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  &.searchResult{
    max-height: 620px;
  }
}
.maxHeightSearch{
  max-height: 380px;
  overflow-y: auto; 
  overflow-x: hidden;
}
.fullHeight{
  margin-bottom: 50px !important;
}
.back-to-top{
  background: $primary-color !important;
  color: #fff;
  padding: 0px 16px;
}

.row-numbers {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #5f4444;
}

.row-numbers-title {
  color: #fff;
  font-size: 36px;
  font-family: "Source Sans Pro Semibold";
}

.row-numbers-subtitle {
  color: #fff;
  font-size: 16px;
  font-family: "Upgrade Devanagari Regular";
}

.rowDataDisplay{
  .row{
    border-bottom: 1px solid #fff; 
    padding: 8px;
    &:hover{
      border-bottom: 1px solid $primary-color;
    }
    &:focus{
      border-bottom: 1px solid $primary-color;
      background: $primary-color;
    }
    &:first-child{
      border-top: 1px solid $primary-color;
    }
  }
}

.searchPagination{
  background: $primary-color; 
  padding: 5px; 
  margin-top: 5px;
  .text{
    font-size: 14px;
    margin: 0 5px; 
    font-weight: 600;
  }
  span{
    display: inline-block;
    color: #fff; 
    line-height: 18px;
    i{
      padding: 8px 12px; 
      font-size: 20px; 
      display: block; 
      cursor: pointer; 
      margin: 0 4px; 
      &:hover{
        background: #ececec;
        color:#000;
      }
    }
  }
  input{
    width: 60px; 
    margin: 0 6px;
    text-align: center;
  }
}


.titleBar{
  background: $primary-color; 
  padding: 5px;
  color: #fff;
  ul{
    margin: 0;
    li{
      display: inline-block;
      padding: 10px;
    }
  }
  &.head{
    font-size: 16px;
    padding: 10px;
  }
  &.nav-tabs{
    border: none;
    padding: 10px 0 0 15px;
    .nav-link{
      border: none;
      color: #fff; 
      border-radius: 0;
      &.active{
        border: none; 
        color: #000;
      }
    }
  }
}
.tab-content{width: 100%;}
.overFlowtext{
  -webkit-line-clamp: 3;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;}

.searchTitleWrap{
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
  .searchTitle{
    line-height: 32px; 
  }
}
.applyFilter{
  margin-bottom: 10px;
  .button{
    margin-right: 10px;
  }
}
.button{
  background: $primary-color!important;
  padding: 10px 15px; 
  border: none;
}

.formAdmin{
  margin: 12% 0;
}
.marginBottom{
  margin-bottom: 15px;
}
.marginTop{
  margin-top: 15px;
}

.whiteBar{
  background: #fff;
  padding: 6px 10px; 
  text-align: right;
  button{
    margin-left: 5px; 
    font-size: 14px; 
    border: none;
  }
}

#modalOpen {
    position: absolute;

    .show {
        display: block;
    }

    .modal-dialog {
        margin-top: 2%;
    }

    .modal-content {
        //background-color:
        //background-repeat: no-repeat;
        //background-size: cover;
        .h4 {
            padding-left: 12px;
            padding-top: 10px;
            font-weight: 500;
        }
    }

    .rowPadding {
        padding-left: 0;
        padding-right: 0;
    }

    .rowMargin {
        margin-left: 10px;
        margin-right: 0;
    }

    .secondary {
        margin-left: 10px;
    }

    .closeIcon {
        position: absolute;
        text-align: right;
        top: -50px;

        i {
            cursor: pointer;
            padding: 5px;
            font-size: 18px;
        }
    }

    .modalMessage {
        text-align: center;
    }

    .reviewModal {
        max-width: 80%;
    }

    .abstractTextarea {
        width: 100%;
        min-height: 100px;
    }

    .modalTextarea {
        width: 100%;
        min-height: 10px;
    }

    .deleteIcon {
        color: #f00;
    }

    .selectedItem {
        font-size: 16px;

        li {
            margin-bottom: 5px;
            cursor: pointer;
        }
    }

    h5 {
        color: #000;
        font-size: 14px;
        font-weight: 600;
    }

    .maxHeight {
        max-height: 80%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .modal-title.h4 {
        font-size: 14px;
        font-weight: 600;
    }
}
.rowDataDisplay {
    .review {
        margin-left: 20px;
        cursor: pointer;
        color: #007bff;
    }
}
#filterShow{
  display: none;
}

.fade.modal-backdrop.hide{
  display: none;
}

.footer{
  font-weight: 700;
  font-size: 16px;
  &.col-sm-12{
    width: 98%;
  }
  #googleLogout{
    display: inline-block;
    margin-left: 15px;
    button {
      > div{
      padding: 5px 8px !important;
      }
      > span{
        padding: 6px 10px 6px 0px !important;
      }
    }
  }
  ul{
    &.socialIcon{
      display: inline-block;
      li{
        display: inline-block;
        margin: 0 5px;
        .fa{
          font-size: 20px;
          &.fa-facebook{
            color:#5f83cf;
          }
          &.fa-twitter{
            color:#73a1fb;
          }
          &.fa-youtube{
            color:#e42b26;
          }
          &.fa-envelope{
            color:#d94103;
          }
        }
      }
    }
  }
}

.scrollToTop{
  position: fixed;
  bottom: 10px;
  right: 10px;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  .topIcon{
    color: #fff;
    background: $primary-color;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-align: center;
    padding-top: 9px;
  }
}

#showPagination li{
  display: inline-block;
}

.leftMenuItem{
  span{
    cursor: pointer;
  }
}

.book-viewer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;

}

.book-header {
  background-color: #5f4444;
  height: 10vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #fff;
  padding: 10px;
}

#book, #canvas_container {
  height: 90vh;
  width: 100vw;
}

#book.BookReader {
  background: linear-gradient(to right, #070000, #4C0001, #070000);
}

.book-reader-header {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
      margin: 20px;
  }
}

#canvas_container {
  background: #333;
  text-align: center;
  border: solid 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#navigation_controls {
  margin-bottom: 1%;
}

.react-pdf__Document {
  display: flex;
  justify-content: center;
}

.react-pdf__Document:hover {
  .pdf-buttons {
    display: block !important;
  }
}

.back-btn {
	margin-right:20px !important;
}

.pdf-buttons {
  display: none;
  position: absolute;
  bottom: 8vh;
  background: #fff;
  padding: 10px;
  width: 200px;
  border-radius: 12px;

  button:disabled {
    background: #CCCCCC;
  }

  button {
    background: #615353;
    height: 35px;
    width: 70px;
    margin: 5px;
    border: 0px;
    border-radius: 10px;
    color: #fff;
  }
}

.BRcontrols {
  .controls {
    background-color: #5f4444;
  }
  .scrubber .BRpager.ui-slider {
    background-color: #fff;
  }
}
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

@font-face {
    font-family: 'Samarkan Normal';
    font-style: normal;
    src: url(assets/fonts/samarkan/SAMARN__.TTF);
}
@font-face {
    font-family: 'Samarkan Oblique';
    font-style: oblique;
    src: url(assets/fonts/samarkan/SAMARO__.TTF);
}

@font-face {
    font-family: 'Ananda Namaste';
    font-style: normal;
    src: url(assets/fonts/ananda-namaste/AnandaNamaste.ttf);
}

@font-face {
  font-family: 'Source Sans Pro Light';
  font-style: normal;
  src: url(assets/fonts/source-sans-pro/sourcesanspro-light.ttf);
}

@font-face {
  font-family: 'Source Sans Pro Regular';
  font-style: normal;
  src: url(assets/fonts/source-sans-pro/sourcesanspro-regular.ttf);
}

@font-face {
  font-family: 'Source Sans Pro Semibold';
  font-style: normal;
  src: url(assets/fonts/source-sans-pro/sourcesanspro-semibold.ttf);
}

@font-face {
  font-family: 'Upgrade Devanagari Medium';
  font-style: normal;
  src: url(assets/fonts/upgrade-devanagari/Upgrade-Devanagari-Medium.otf) format("opentype");;
}

@font-face {
  font-family: 'Upgrade Devanagari Regular';
  font-style: normal;
  src: url(assets/fonts/upgrade-devanagari/Upgrade-Devanagari-Regular.otf) format("opentype");;
}

body{
  background-color: #fff; 
}
.App {
    color: #000;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 300;
    font-family: 'Upgrade Devanagari Regular', 'Ananda Namaste','Samarkan Oblique','Samarkan Normal';
    /*  font-family: 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;*/
    min-height: 750px;
    position: relative;
    background-position-x: -45px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #000;
}


.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
 
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
 
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
 
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
 
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
 
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}






